<template>
  <div class="csn-lsm" :class="{ 'csn-lsm-displayed': SHOWS_LOGIN_SIDE_MENU }">
    <div class="csn-lsm-header">
      <button @click="handleArrowClick" class="csn-lsm-arrow-back">
        <SlideArrow />
      </button>
      <NavbarLogoButton @click.native="handleCloseClick" />
      <button @click="handleCloseClick" class="csn-notification-menu-close-btn">
        <CloseIcon />
      </button>
    </div>
    <Login @close="handleRedirect" />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  LOGIN_SIDE_MENU,
  TOGGLE_LOGIN_SIDE_MENU,
  SHOWS_LOGIN_SIDE_MENU,
  TOGGLE_MAIN_MENU,
  Module,
  RouteName,
  Digit,
} from '@/constants'
import { navigateTo, callFn, requestTimeout, cancelTimeout } from '@/helpers'

export default {
  name: LOGIN_SIDE_MENU,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    NavbarLogoButton: () =>
      import('@/App/components/Navbar/components/NavbarLogoButton'),
    Login: () => import('@/modals/Login'),
  },
  data: () => ({
    openNextSideMenu: null,
  }),
  computed: {
    ...mapState(Module.MAIN, [SHOWS_LOGIN_SIDE_MENU]),
  },
  methods: {
    ...mapMutations(Module.MAIN, [TOGGLE_LOGIN_SIDE_MENU, TOGGLE_MAIN_MENU]),
    async handleArrowClick() {
      await navigateTo({
        name: RouteName.HOME,
      })

      this.TOGGLE_LOGIN_SIDE_MENU(false)
      this.TOGGLE_MAIN_MENU()
    },
    handleCloseClick() {
      this.TOGGLE_LOGIN_SIDE_MENU(false)

      navigateTo({
        name: RouteName.HOME,
      })
    },
    handleRedirect(redirect) {
      this.TOGGLE_LOGIN_SIDE_MENU(false)

      this.$nextTick(() => {
        this.openNextSideMenu = requestTimeout(() => {
          cancelTimeout(this.openNextSideMenu)
          this.openNextSideMenu = null
          callFn(redirect)
        }, Digit.TWO_HUNDRED)
      })
    },
  },
}
</script>
